import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { IOption } from '@typings/common'

type UserSettingToggleGroupProps = {
  label: string
  options: IOption<string>[]
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  optionWidth?: number
}

const UserSettingToggleGroup = ({
  label,
  options,
  value,
  onChange,
  disabled,
  optionWidth = 100,
}: UserSettingToggleGroupProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      sx={{ filter: disabled ? 'grayscale(1)' : 'none', opacity: disabled ? 0.6 : 1 }}
    >
      <Typography fontSize={14} color="grey.800" width={140}>
        {label}
      </Typography>
      <Box display="flex" fontSize={13} fontWeight={500} borderRadius={1} width="fit-content" overflow="hidden">
        {options.map((o) => (
          <Box
            key={o.value}
            component="button"
            disabled={disabled}
            sx={{
              py: 1,
              width: optionWidth,
              color: value === o.value ? 'white' : 'info.dark',
              bgcolor: value === o.value ? 'primary.dark' : 'grey.200',
              cursor: disabled ? 'not-allowed' : 'pointer',
              textAlign: 'center',
              ':hover': {
                bgcolor: value === o.value ? 'primary.dark' : 'grey.300',
              },
              outline: 'none',
              border: 'none',
            }}
            onClick={() => onChange(o.value)}
          >
            {o.label}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default UserSettingToggleGroup
